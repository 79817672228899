<template>
	<ace-editor v-if="styleCopy" ref="ace" id="editor" v-model="styleCopy.val" @init="editorInit" lang="scss" :theme="theme" v-bind:class="{'has-gutter': editorOptions.showGutter}" style="font-size: 13px !important;"></ace-editor>
</template>

<script>
const cssBeautify = require('js-beautify').css;
import {
	mapActions
} from 'vuex';
export default {
	name: 'CodeEditor',
	props: [
		'options',
		'tabUrlKey',
		'editorType',
		'mode'
	],
	data () {
		return {
			style: false,
			styleCopy: false,
			newStyle: false,
			justAutoInserted: false,
			undoAutoInsert: false,
			autosavePort: this.editorType != 'app' ? this.$chrome.runtime.connect({name: "autosave"}) : false,
			autoUpdateTimeout: false,
			typing: false
		};
	},
	methods: {
		editorInit (editor) {
			require('ace-builds/src-min-noconflict/ext-language_tools');
			require('ace-builds/src-min-noconflict/mode-scss');
			editor.session.setMode("ace/mode/scss");
			/* Other ACE Settings */
			// editor.renderer.setScrollMargin(5, 10);
			editor.session.setUseSoftTabs(true);
			editor.setAutoScrollEditorIntoView(true);
			editor.session.setUseWrapMode(true);
			editor.session.setTabSize(4);
			editor.container.style.lineHeight = 1.5;
			editor.setShowPrintMargin(false);
			editor.setFontSize(14);
			editor.resize();
			editor.commands.addCommand({
				name: "save",
				exec: this.save,
				bindKey: {mac: "cmd-s", win: "ctrl-s", sender: 'editor|cli'}
			});
			editor.setOptions(this.editorOptions);
		},
		beautify () {
			this.styleCopy.val = cssBeautify(this.styleCopy.val);
		},
		hasUnsavedChanges () {
			return this.styleCopy.val !== this.style.val;
		},
		save () {
			this.typing = false;
			this.$emit('onsave');
			this.styleCopy.draft = false;
			this.styleCopy.commit();
			this.undoAutoInsert = false;
			if (!this.newStyle && !this.style.val.length) {
				this.style.remove();
				return true;
			}
			let saveMethod;
			if (this.newStyle && this.style.val.length) {
				saveMethod = this.style.save();
			} else {
				saveMethod = this.style.patch();
			}
			return saveMethod.then(res => {
				this.$emit('onsuccess', this.style);
				this.newStyle = false;
				this.storeDraft(this.style.key, false);
				return res;
			}).catch(err => {
				console.log(err);
				this.$emit('onerror', err);
				return false;
			});
		},
		copyCode () {
			return this.$copyText(this.styleCopy.val);
		},
		discardChanges () {
			this.styleCopy.reset();
			if (!this.style.val.length) {
				this.style.remove();
			}
		},
		loadCode (changes) {
			if (!changes) {
				this.findStyle({ query: { key: this.tabUrlKey } }).then(res => {
					if (res.total === 0) {
						this.style = new this.Styles({key: this.tabUrlKey, mode: this.mode});
						this.newStyle = true;
						this.$emit('onloaded', this.style);
					} else {
						this.style = new this.Styles(res.data[0]);
						this.$emit('onloaded', this.style);
					}
					this.styleCopy = this.style.clone();
					if (this.style.draft) {
						this.$nextTick(() => {
							this.styleCopy.val = this.style.draft;
						});
					}
				});
			} else if (changes.key == this.style.key && !this.typing) {
				if (this.styleCopy.val !== changes.val || this.styleCopy.draft !== changes.draft) {
					this.style = new this.Styles(changes);
					this.styleCopy.val = this.style.val;
					if (this.style.draft) {
						this.$nextTick(() => {
							this.styleCopy.val = this.style.draft;
						});
					}
				}
			}
		},
		removeCode (changes) {
			if (changes.key === this.tabUrlKey) {
				this.style = new this.Styles({key: this.tabUrlKey, mode: this.mode});
				this.styleCopy = this.style.clone();
				this.newStyle = true;
				this.$emit('onloaded', this.style);
			}
		},
		handleDevToolsSelection () {
			this.$chrome.devtools.panels.elements.onSelectionChanged.addListener(() => {
				this.$chrome.devtools.inspectedWindow.eval('getSelector($0)', {useContentScriptContext: true}, this.autoInsertSelector);
			});
		},
		autoInsertSelector (selector) {
			const { editor } = this.$refs.ace;
			if (this.undoAutoInsert) {
				editor.undo();
			}
			const insertPos = editor.session.getLength();
			const insertVal =  (insertPos > 1 ? "\n\n" : "") + selector + " {\n\t\n}";
			editor.session.insert({
				row: insertPos,
				column: 0
			}, insertVal);
			editor.gotoLine(insertPos + (insertPos > 1 ? 3 : 1), 0);
			editor.focus();
			editor.execCommand("gotolineend");
			this.justAutoInserted = true;
		},
		storeDraft (key, editorValue) {
			if (this.autosavePort) {
				this.autosavePort.postMessage({ key, editorValue });
			}
		},
		async saveDraft () {
			console.log('SAVING DRAFT...');
			if (this.hasUnsavedChanges()) {
				if (this.autosavePort) {
					this.autosavePort.postMessage({ save: true });
				} else {
					this.style.draft = this.styleCopy.val;
					await this.style.save();
					return;
				}
			}
		},
		removeDraft () {
			this.style.draft = false;
			this.style.save();
		},
		...mapActions('styles', {
			findStyle: 'find'
		})
	},
	computed: {
		editorOptions () {
			return {
				showGutter: this.options.gutter,
				enableBasicAutocompletion: this.options.autocomplete,
				enableLiveAutocompletion: this.options.autocomplete
			}
		},
		code () {
			return this.style.val;
		},
		theme () {
			return this.options.theme === 'dark' || this.editorType === 'app'  ? 'ambiance' : 'chrome';
		},
		Options () {
			const { Options } = this.$FeathersVuex.api;
			return Options;
		},
		Styles () {
			const { Styles } = this.$FeathersVuex.api;
			return Styles;
		}
	},
	watch: {
		/*style: {
			handler: function () {
				this.styleCopy = this.style.clone();
			},
			deep: true
		},*/
		styleCopy: {
			handler: function () {
				if (this.options.autoupdate && !this.justAutoInserted) {
					if (this.hasUnsavedChanges()) {
						this.typing = true;
						this.$emit('typing');
						if (this.autoUpdateTimeout !== false) { clearTimeout(this.autoUpdateTimeout); }
						this.autoUpdateTimeout = setTimeout(this.save, 700);
					}
				} else {
					if (this.hasUnsavedChanges()) {
						this.storeDraft(this.styleCopy.key, this.styleCopy.val);
					} else {
						this.storeDraft(this.styleCopy.key, false);
					}
					this.$emit('modified', this.hasUnsavedChanges());
					if (this.justAutoInserted) {
						this.undoAutoInsert = true;
						this.justAutoInserted = false;
					} else {
						this.undoAutoInsert = false;
					}
				}
			},
			deep: true
		},
		tabUrlKey: function () {
			this.saveDraft();
			this.loadCode();
		},
		editorOptions: {
			handler: function () {
				this.$refs.ace.editor.setOptions(this.editorOptions);
			},
			deep: true
		}
	},
	mounted () {
		this.loadCode();
		this.Styles.on('created', this.loadCode);
		this.Styles.on('patched', this.loadCode);
		this.Styles.on('removed', this.removeCode);
		if (this.editorType == 'devtools') {
			console.log('DEVTOOLS');
			this.handleDevToolsSelection();
		}
		//window.addEventListener('beforeunload', this.protectChanges);
	},
	unmounted () {
		this.Styles.off('created', this.loadCode);
		this.Styles.off('patched', this.loadCode);
		this.Styles.off('removed', this.removeCode);
		//window.removeEventListener('beforeunload', this.protectChanges);
	}
}
</script>
